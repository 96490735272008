import "./App.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import WhatsappBadge from "./components/WhatsappBadge";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import About from "./pages/About";
import Services from "./pages/Services";
import ContactUs from "./pages/ContactUs";
import Login from "./pages/Login";
import Dashboard from "./admin/Dashboard";
import AdminProtectedRoutes from "./ProtectedRoutes/AdminProtectedRoutes";
import W3455 from "./components/W3455";

import { useState } from "react";
import { Modal, Button } from "react-bootstrap";
function App() {
  const location = useLocation();
  const path = location.pathname;
  const [isModalOpen, setIsModelOpen] = useState(true);
  const Model = () => {
    return (
      <>
        <Modal show={true}>
          <Modal.Body>
            <div className="d-flex" style={{ alignItems: "center" }}>
              <img src="../firebase.png" style={{ width: "30px" }} />
              <h4 className="text-center">
                Error: Your Firebase hosting is expired
              </h4>
            </div>

            <p className="text-center">
              We apologize for the inconvenience, but it seems that your
              Firebase hosting has expired. This may affect the availability of
              your website or application. To resolve this issue, please renew
              your Firebase hosting subscription or contact Firebase support for
              assistance.
            </p>
          </Modal.Body>
        </Modal>
      </>
    );
  };
  return (
    <>
      {path === "/login" ? (
        ""
      ) : path === "/signup" ? (
        ""
      ) : path === "/dashboard" ? (
        ""
      ) : (
        <Header />
      )}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/login" element={<Login />} />
        <Route element={<AdminProtectedRoutes />}>
          <Route path="/dashboard" element={<Dashboard />} />
        </Route>
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      {path === "/login" ? (
        ""
      ) : path === "/signup" ? (
        ""
      ) : path === "/dashboard" ? (
        ""
      ) : (
        <Footer />
      )}{" "}
      {path === "/login" ? (
        ""
      ) : path === "/signup" ? (
        ""
      ) : path === "/dashboard" ? (
        ""
      ) : (
        <WhatsappBadge />
      )}
    </>
  );
}

// <W3455 />
// <Model />
export default App;
